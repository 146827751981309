.landing_blog_outline {
  background-color: #f2f2f2;
  margin-left: -15px !important;
  width: 100vw;
  // height: 500px;
  padding-bottom: 50px;
  text-align: center;

  &_header {
    margin-top: 50px;

    h3 {
      font-weight: bold;
    }
  }

  &_cards {
    margin-top: 20px;

    &_card {
      justify-content: center;
      column-gap: 20px;

      .blog-title-truncate {
        // width: 420px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .blog-description-truncate {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: justify;
        font-weight: none !important;

        h1,
        h2 {
          font-size: 16px !important;
        }
      }
    }
  }
}

.scholarship_blog_outline {
  padding: 60px 0px;

  &_content {
    padding: 0px 100px;

    &_prgh {
      //   padding: 0px 40px;
      text-align: justify;

      div {

        h1,
        h2 {
          font-size: 22px !important;
        }
      }


    }

    &_image {
      padding-top: 20px;
      text-align: center;

      padding-bottom: 40px;
    }
  }

  @media (max-width: 991px) {
    &_content {
      padding: 0px;

      &_prgh {
        //   padding: 0px 40px;
        text-align: justify;

        div {

          h1,
          h2 {
            font-size: 20px !important;
          }
        }
      }

      &_image {
        padding-top: 20px;
        text-align: center;
        padding-bottom: 40px;
      }
    }
  }
}

// FAQ
.FAQ_outline {
  background-color: rgb(242, 242, 242);
  // background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
  width: 100vw;
  margin-left: -15px;
  padding: 0px 120px 50px 120px;

  @media (max-width: 600px) {
    padding: 20px;
  }

  &_header {
    margin-top: 50px;
    text-align: center;
    cursor: pointer;

    h3 {
      font-weight: bold;
    }
  }

  &_contents {
    margin-top: 20px;

    &_content {
      font-weight: bold;
      font-size: 16px;
      padding: 0px 10px;
      cursor: pointer;

      span:last-child {
        float: right;
      }
    }

    &_description {
      padding: 12px 10px;
    }
  }
}

// Landing Video

.video_outline {
  padding-bottom: 50px;

  &_header {
    margin-top: 50px;
    text-align: center;

    h3 {
      font-weight: bold;
    }
  }

  &_contents {
    margin-top: 20px;
  }

  .faq-card-view {
    color: black;
    padding: 10px;
    background-color: rgb(242, 242, 242);
  }

  .react-multi-carousel-track {
    column-gap: 20px;
  }
}

//BGJ Banner
.BGJ_outline {
  &_header {
    margin-top: 50px;
    text-align: center;

    h3 {
      font-weight: bold;
      color: #fff;
    }
  }

  &_contents {
    margin: 50px 0px;
    text-align: center;

    img {
      height: 25vh;
    }
  }
}