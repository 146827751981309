*{
    font-family: 'Roboto', sans-serif;
}
.terms-outline {
  padding: 50px 0px;
  text-align: justify;
  &-center {
    text-align: center;
    margin-bottom: 30px;
  }
}
.terms-ouline-article1 {
  margin-bottom: 30px;
  color: black;
}
.a li {
  list-style-type: circle;
  //list-style-position: inside;
  margin-bottom: 1rem;
  color: black;
}
