.chat_main_content {
    // height: -o-calc(100% - 120px);
    // /* opera */
    // height: -webkit-calc(100% - 120px);
    // /* google, safari */
    // height: -moz-calc(100% - 120px);
    /* firefox */
    height: calc(100vh - 120px) !important;
    text-align: center;
}

.sendbird-channel-header__right-icon {
    display: none;
}

.sendbird-members-accordion__footer {
    display: none !important;
}

.chat_circle {
    position: relative;
    background-color: #ff0000;
    color: #fff;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    line-height: 0;
    float: right;

    &_notification {
        position: absolute;
        bottom: 50%;
        width: 100%;
        text-align: center;

        /* (E2) THE FONT - NOT REALLY IMPORTANT */
        font-family: arial, sans-serif;
        font-weight: bold;
    }
}

.chat_circle::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.chat_icon {
    position: relative;
    line-height: 0;

}

.chat_icon::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.chat_text {
    position: absolute;
    bottom: 50%;
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
    margin-left: 10px
}

.chat {
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 18px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .chat {
        margin-right: 4px;
        margin-left: -140px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .chat {
        margin-right: 4px;
        margin-left: 235px;
    }
}

@media (min-width: 500px) and (max-width: 575px) {
    .chat {
        margin-right: -10px;
        margin-left: 40px;
    }
}

@media (min-width: 401px) and (max-width: 499px) {
    .chat {
        margin-right: -26px;
        margin-left: 75px;

    }
}

@media (min-width: 360px) and (max-width: 400px) {
    .chat {
        margin-right: 90px;
        margin-left: -70px;
        margin-top: 27px;
    }
}

// CustomizedHeader
.customize_school {
    &_icon {
        color: #10a9ac !important;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        padding: 0px;
        border: 1px solid;
    }

    &_link {
        cursor: pointer;
        margin: 5px 0px 0px 10px;

        &_name {
            font-weight: bold;
        }

        &_id {
            font-weight: bold;
        }
    }
}

// Customize student header
.customize_student {
    &_icon {
        width: 35px;
        height: 35px;
        padding: 0px;
        margin-right: 10px;
        margin-top: -5px;

    }

    &_link {
        cursor: pointer;
        margin: 5px 0px 0px 10px;

        &_name {
            font-weight: bold;
        }

        &_id {
            font-weight: bold;
        }
    }
}

// Left side channelList of student
.channel_left_list {
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: #eeeeee;
    width: 319px;
    padding: 10px 0px 10px 10px;
    border: 1px solid lightgray;
    color: #000;

    &_image {
        color: #10a9ac !important;
        border-radius: 50%;
        width: 53px;
        height: 53px;
        padding: 0px;
        border: 1px solid;
    }

    &_time {
        font-size: 10px;
    }

    &_name {
        padding: 5px 10px;

        &_unread_msg {
            padding: 5px 10px;
            font-weight: bold;
        }
    }


    &_message {
        padding: 0px 10px 0px 10px;
        font-size: 10px;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &_unread_msg {
            padding: 0px 10px 0px 10px;
            font-size: 10px;
            font-weight: bold;
            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &_message_count {
        margin-left: auto;
        margin-right: 10px;
        background-color: #10a9ac;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: white;
    }
}

// .channel_left_list:hover {
// background-color: #e0e0e0;
.channel_left_list_dot {
    border-radius: 50%;
    height: 4px;
    width: 4px;
    background: linear-gradient(90deg, #10a9ac, #10a9ac);
    margin: 2px auto;
}

.channel_left_list_dot-wrapper {
    width: min-content;
    margin-left: auto;
    margin-right: 10px;
}

// }


// .channel_left_list_leave{
//     opacity: 0;
//     z-index: 1;
//     // visibility: hidden;
// }
// .channel_left_list_dot-wrapper:hover{
//     .channel_left_list_leave{
//         background-color: #ff0000;
//         display: initial;
//     }
// }
// .channel_left_list_leave{
//     background-color: #ff0000;
//     display: none;
// }
//Channel left top-header
.sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title__right {
    margin-left: 7px;
    padding-top: 17px;

    &__name {
        font-weight: bold;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__user-id {
        display: none;
    }
}

// left side channel lsit of student
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__total-members {
    display: none;
}
.sendbird-members-accordion__footer{
    display: none !important;
}
