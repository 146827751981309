#student-top-page-container {
  background-color: #fff;
  font-family: "roboto", "sans-serif";
}
.school-top-page-align {
  // *{
  //   color: black;
  // }
  text-align: center;
  button {
    margin-top: 10px;
    margin-bottom: 40px;
    color: white;
    background-color: #7634a8;
    padding: 10px 50px;
    border-radius: 40px;
  }
}
.school-top-page-bottom {
  margin-bottom: 100px;
}
.top-page-usercontent {
  margin-top: 100px;
  margin-bottom: 25px;
  font-weight: bolder;
}
.top-page-circlecontent {
  margin-bottom: 25px;
  font-weight: bolder;
}
.block {
  text-align: center;
  vertical-align: middle;
}
.student-top-image {
  .circle {
    // background: #bfb9fa;
    background: #ddeee5;
    border-radius: 200px;
    color: black;
    height: 200px;
    // font-weight: bold;
    width: 200px;
    display: table;
    margin: 20px auto;
    object-fit: cover;
    padding: 8px;
    p {
      vertical-align: middle;
      display: table-cell;
      padding: 10px;
      font-size: 16px;
    }
  }
}

.small-circle {
  height: 100px;
  width: 100px;
  background-color: #bbb;
  border-radius: 50%;
}
.top-page-home {
  display: flex;
  flex-direction: row;
}
.top-page-doc {
  margin-bottom: 50px;
}
.stu-circle {
  border: 1px solid #f5f5f5;
  border-radius: 200px;
  color: black;
  height: 250px;
  font-weight: bold;
  width: 250px;
  object-fit: cover;
}
.underline {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 8px solid #ffcc00;
}
.stu-top-page-font {
  font-size: 20px;
}

.stu-top-page-service-heading {
  font-size: 32px;
  // color: #7634a8;
  color: #10a9ac;
}
