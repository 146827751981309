.student-course-profile {
  .course-text-area {
    padding: 0.75rem 0.5rem !important;
    min-height: 90px;
  }
}

.school-profile-content {
  .form-control {
    height: 3em !important;
  }
}

.school-predit-admission {
  .course-text-area {
    padding: 0.75rem 0.5rem !important;
    min-height: 90px;
  }
}

.school-logo-outline-upload {
  .school-logo-upload {
    border-radius: 20px;
    color: white;
    background-color: #5575df;
  }

  .school-pr-label {
    label {
      margin-bottom: 0px !important;
    }
  }
}

@media (max-width: 991px) {
  .school-item {
    text-align: center;

    &-logo-mid {
      text-align: center;
    }
  }
}

// Students Application Confirmation
.student-application-outline {
  .student-app-confirm {
    height: 160px;
    color: white;
    padding: 10px;
    background-color: #116c6e;
    text-align: center;
    position: fixed;
    z-index: 90;

    &-header {
      margin-bottom: 5px;
      font-size: 30px;
      font-weight: bold;
      justify-content: center;
    }
  }

  .student-app-all-detail {
    padding-top: 180px;
    padding-right: 30px;
    padding-left: 30px;

    .redirect-lnk {
      li {
        margin-bottom: 10px;

        a {
          font-size: 14px !important;
          margin-bottom: 5px;
          color: $page-color;
        }
      }
    }
  }

  #studentFooter {
    button {
      padding: 5px 65px;
      background-color: white;
      border-radius: 40px;
      border: none;
      color: #116c6e;
    }
  }
}

@media (max-width: 991px) {
  .student-application-outline {
    .student-app-confirm {
      height: fit-content;
      color: white;
      padding: 10px;
      background-color: #116c6e;
      text-align: center;
      position: fixed;
      z-index: 90;

      &-header {
        font-size: 15px;
      }
    }

    .student-app-all-detail {
      padding-top: 100px;
      padding-right: 30px;
      padding-left: 30px;
    }

    #studentFooter {
      position: fixed;
      background-color: #116c6e;
      bottom: 0;
      width: 100%;
      padding: 15px;
      justify-content: center;

      button {
        background-color: white;
        border-radius: 40px;
        border: none;
        color: #116c6e;
        padding: 5px 10px;
      }
    }

    .dropdown {
      // width: 100px;
      // background: purple;
    }

    .dropdown .item {
      display: none;

      ul {
        background-color: #f5f5f5;
        color: #116c6e;
        text-align: initial;
        padding: 20px;
        margin: 0px 10px 0px -88px;
      }
    }

    .dropdown:hover .item {
      display: block;
    }
  }
}

///`removing button border line on focus
button {
  border: none !important;

  &:focus {
    border: none !important;
  }
}

.student-form {
  .nav-tabs {
    .student-profile-main-tab {
      .nav-link {
        background-color: #c7c7c7 !important;
        color: white !important;
        text-decoration: underline !important;
      }

      .active {
        background-color: white !important;
        color: black !important;
        font-weight: bold;
        text-decoration: none !important;
      }
    }
  }
}

.admission-application-content-page {
  .activePage {
    background-color: #10a9ac !important;
    border: 1px solid #707070 !important;
    color: #fff !important;
  }

  .unactivePage {
    background-color: #fff !important;
    border: 1px solid #707070 !important;
    color: black !important;
  }
}

//Footer Media Page
.social-media-header-content {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.1;
  margin-top: 5rem !important;
}

.social-media-facebook-content {
  // display: flex;
  margin-top: 0px;
  padding-bottom: 3rem;
  // justify-content: center;
}

//PieChart Content
.recharts-legend-wrapper {
  right: -150px !important;
  top: -30px !important;
}

// Common below Header Button
.common-button-design {
  background-color: #FDA520;
  cursor: pointer;
  color: white;
  padding: 6px;
  border-radius: 10px;
}

// Scout Personal Info Chat Icon
.chat_icon_hover:hover {
  color: #4d2aa6;
  // color: linear-gradient(to right, #7634a8, #5575df);
}

.active_chat {
  background-color: lightcyan;
}

.unactive_chat {
  background-color: whitesmoke;
}

// JUMP TO SPECIFIC PARTS OF A WEB PAGE BY USING THE ANCHOR TAG
#personalInfoSection,
#passportInfoSection,
#friendsFamilySection,
#maritalStatusSection,
#educationBackgroundSection,
#professionalCareerSection,
#experienceStudyingJapaneseSection,
#japaneseProficiencySection,
#planAfterGraduationSection,
#sponsorSection {
  scroll-margin-top: 120px;
}

// Duplicate one: remove later
#personalInfoSection1,
#passportInfoSection1,
#friendsFamilySection1,
#maritalStatusSection1,
#educationBackgroundSection1,
#professionalCareerSection1,
#experienceStudyingJapaneseSection1,
#japaneseProficiencySection1,
#planAfterGraduationSection1,
#sponsorSection1 {
  scroll-margin-top: 300px;
}

// make student search detail page anchor tag active
.anchor_active {
  color: #10a9ac !important;
  font-size: 18px;
}

.anchor_unactive {
  font-size: 18px;
}

.active_chat {
  background-color: #E2DFFF;
  ;
  // color: #fff;
}

.unactive_chat {
  background-color: #fff;
}