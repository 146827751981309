.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-40 {
  font-size: 40px;
}
