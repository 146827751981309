.onboarding {
  background: #f6f6f6 !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  max-width: 100% !important;


  .form {
    background: #fff !important;
    justify-content: center;
    padding: 60px 30px 70px 30px !important;
    @media screen and (max-width:450px) {
      padding: 40px 12px 30px 12px !important;

    }

    .label {
      font-weight: 600 !important;
      color: #333333;
      margin-bottom: 0.25rem;
    }
    .sub-label {
      font-weight: 400 !important;
      font-size: 14px;
      color: #333333;
      margin-bottom: 0.25rem;
    }
    .input {
      padding: 0.5rem 0.5rem !important;
      font-size: 0.9rem !important;
    }
    .form-onboarding-gap{
      margin-bottom: 0rem;
    }
  }
  .react-phone-form{
  @include phone-number-outline;
  }
  .form_error_margin{
    // background-color: red;
    margin: -15px 0px 0px 0px;
  }
}
