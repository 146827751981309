//Event List
.event_list_outline {
    &_headline {
        color: #10A9AC;
    }

    &_body {
        border: 1px solid #707070;
        border-radius: 5px;
        margin: 20px;
        padding: 10px 0px 10px 0px;

        span:first-child {
            color: #10A9AC;
            margin-right: 20px;
        }

        span:nth-child(2) {
            color: #10A9AC;
            margin-right: 20px;
        }

        &_bottom {
            font-weight: bold;
            font-size: 18px;
        }
    }

}

// Event Detail
.event_detail_outline {
    background-color: #fff;

    &_body {
        margin: 90px 30px 0px 30px;

        &_top {
            color: #3252B4;
            text-decoration: underline;
        }

        &_image {
            height: 480px;
            width: 1200px;
            // object-fit: contain;
        }

        &_title {
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &_date {
            font-size: 16px;
        }

        &_deadline {
            font-size: 14px;
        }

        &_content {
            font-size: 16px;
            margin-top: 20px;
            text-align: justify;        }

        &_center {
            text-align: center;
        }

        &_apply {
            margin: 10px 0px;
        }

        &_border {
            border: 1px solid #A0A0A0;
            padding: 15px;
            margin: 20px 0px;
            border-radius: 5px;

            div {
                margin-bottom: 14px;
                span:first-child {
                    color: #4DADAF; 
                }
            }
        }

    }


}