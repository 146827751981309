@mixin background-outline {
  background-color: #f5f5f5;
}
@mixin background-inline{
    background-color: #fff;
}
@mixin headerclr {
    background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
    padding: 10px;
    color: white;
  }
  
@mixin agent-align-content {
    background-color: $white;
    padding: 60px;
  }

  @mixin lCircle {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto;
    position: relative;
    object-fit: cover;
    border: 1px solid;
  } 