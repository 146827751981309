.school-headerclr {
  // background: #5B5B5B;
  background-image: linear-gradient(
    to right,
    #7634a8,
    #5575df,
    // #4c46e6,
    // rgb(151, 0, 255)
  );
  color: $white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.school-prof-upload {
  background-color: #5575df;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
}

.school-logo-outline {
  margin-bottom: 15px;

  &-upload {
    margin: auto 0px;
  }

  &-image {
    margin-right: 20px;
  }
}
.circle {
  width: 20px;
  height: 20px;
  // line-height: 500px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  background-color: transparent;
  border: 1px solid #fff;
}

//Student Documents
.student-document-truncate {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-document-float {
  float: right;
}
//and (max-width: 991.98px)

.school-profile-image {
  height: 130px;
}

.student-document-section {
  @media (max-width: 440px) {
    display: block;
    // .student-document-upload-section {
    //   div:nth-child(2) {
    //     margin-left: 166px;
    //   }
    // }
  }
  .student-document-upload-section {
    .student-document-float {
      @media (min-width: 434px) and (max-width: 440px) {
        margin-top: 100px;
        text-align: inherit;
      }
    }
    .student-document-upload-view {
      @media (max-width: 440px) {
        max-width: 100% !important;
      }
    }
    .student-document-upload-button {
      @media (max-width: 440px) {
        margin-left: 166px;
      }
    }
    .student-document-upload-design {
      @media (max-width: 440px) {
        margin-left: 80px;
        margin-top: 20px;
      }
    }
    .student-document-display {
      display: flex;
      div {
        margin-right: 10px;
      }
      @media (min-width: 440px) and (max-width: 768px) {
        display: block;
        margin-right: 0px;
      }
    }
  }
}
