.app-list {
  background-color: #f5f5f5;
  //   minHeight: "220px",
  &-header {
    color: white;
    background-color: #116c6e;
    align-items: center;
    height: 60px;
    position: fixed;
    right: 0;
    z-index: 99;

    justify-content: space-between;
    // padding: 50px;
    div:first-child {
      float: left;
      font-size: 30px;
      padding-left: 30px;
    }
    div:last-child {
      font-size: 15px;
      padding-right: 30px;
      color: white;
    }
  }
 
  &-student-header {
    color: white;
    background-color: #116c6e;
    align-items: center;
    height: 60px;
    position: fixed;
    right: 0;
    z-index: 99;
    justify-content: space-between;
    // padding: 50px;
    div:first-child {
      float: left;
      font-size: 30px;
      padding-left: 30px;
    }
    div:last-child {
      font-size: 15px;
      padding-right: 30px;
      color: white;
    }
  }
  &-container {
    background-color: #fff;
  }
  &-button {
    text-align: center;
    button {
      color: #fff;
      width: 80% !important;
      border-radius: 5px;
      // height: 30px;
      background-color: #e2e2e2;
      border: none;
      font-weight: 600;
      padding: 10px;
      font-size: 20px;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
@media (max-width:991px) {
  .app-list {
    &-header {
      color: white;
      background-color: #116c6e;
      align-items: center;
      height: 60px;
      position: fixed;
      right: 0;
      z-index: 99;
  
      justify-content: space-between;
      // padding: 50px;
      div:first-child {
        float: left;
        font-size: 20px !important;
        padding: 0px !important;
      }
      div:last-child {
        font-size: 16px !important;
        color: white;
        padding: 0px !important;
      }
    }
  }
}