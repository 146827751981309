.download-app--three {
  width: 100%;
  // background-image: url('https://webstrot.com/html/jbdesk/main_version/main_pages/images/apps.jpg');
  background-position: center 0;
  background-size: cover;
  padding: 200px 0;
  overflow: hidden;
  padding-bottom: 220px;
  @media (max-width: 1199px) {
    padding: 100px 0;
  }
  .line-shape {
    position: absolute;
    top: 30%;
    left: -150px;
    @media (max-width: 1199px) {
      display: none;
    }
  }
  .mockup {
    left: -300px;
    position: absolute;
    top: -199px;
    @media (max-width: 1199px) and (min-width: 991px) {
      left: -125px;
      top: -51px;
    }
    @media (max-width: 1500px) {
      left: -145px;
    }
    @media (max-width: 1750px) {
      top: -132px;
    }
    @media (max-width: 991px) {
      display: none;
    }
    img {
      position: relative;
      z-index: 1;
      -webkit-animation: movebounce 3.9s linear infinite;
      animation: movebounce 3.9s linear infinite;
      @media (max-width: 1199px) and (min-width: 991px) {
        width: 76%;
        left: 121px;
        top: 75px !important;
      }
      @media (max-width: 1330px) and (min-width: 1200px) {
        width: 700px;
        left: 121px;
      }
      @media (max-width: 1500px) {
        position: relative;
        top: -18px;
      }
      @media (max-width: 1750px) and (min-width: 1331px) {
        width: 750px;
      }
    }
  }
  .app-store {
    @media (max-width: 991px) {
      text-align: center !important;
    }
    h1 {
      color: $black-shade;
      font-size: 44px;
      padding-bottom: 10px;
    }
    h2 {
      color: $black-shade;
      font-size: 44px;
    }
    p {
      color: $black-shade;
    }
    &--btn {
      margin-top: 40px;
      @media (max-width: 991px) {
        justify-content: center !important;
        flex-wrap: wrap !important;
      }
      .app-button {
        &.app-button--two {
          @media (max-width: 465px) {
            margin-left: 8px !important;
          }
        }
        &:hover {
          // background: $blue-violet-gradient;
          background: $green-blue-gradient;
          border: 1px solid transparent;
        }
        i {
          padding: 0.75rem 1.25rem;
        }
        span {
          text-transform: capitalize;
          padding: 0 1.9rem;
        }
      }
    }
  }
}
