.content-icon {
  ul {
    display: flex;
    padding-top: 50px !important;
    li {
      margin-right: 45px;
      a {
        &:hover {
          .icon {
            // color: $blue-violet;
            color: $green-blue;
          }
        }
        .icon {
          color: #c6c6c6;
          font-size: 28px;
          transition: all 0.6s;
        }
      }
    }
  }
}
