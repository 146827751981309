@mixin phone-number-outline {
  :focus {
    outline: none !important;
  }
  input:focus {
    outline: none !important;
  }
  input {
    border: none !important;
  }
}

@mixin space-after-form-input($space) {
  border-radius: $border-radius !important;
  height: calc(1.5em + 1.5rem + -6px) !important;
  margin-bottom: $space;
}
