header {
  z-index: 10000;
  transition: all 0.5s ease;
  @media (max-width: 991px) {
    // line-height: 80px;
    //height: 80px;
  }
  // @media (min-width: 991px) {
  //   height: 120px;
  // }
  @media (min-width: 1795px) {
    .nav-col {
      padding-left: 70px;
    }
  }

  @media (min-width: 882px) and (max-width: 991px) {
    .user-info {
      .media {
        margin-left: 60px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .user-info {
      .media {
        .media-left {
          img {
            margin-left: 40px;
          }
        }
      }
    }
  }
  .navigation {
    text-transform: capitalize;
    .nav-item {
      .main-nav {
        padding: 40px 22px;
        &:hover,
        &.active {
          color: $primary !important;
          transition: all 0.5s;
          &::before {
            position: absolute;
            content: '';
            opacity: 1;
            background: $primary;
            width: 60px !important;
            height: 3px;
            left: 0px;
            right: 0;
            margin: 0px auto;
            bottom: 25px;
            transition: all 500ms ease;
            -webkit-mask-image: linear-gradient(
              -75deg,
              rgba(244, 55, 55, 0.24) 50%,
              $primary 50%,
              rgba(0, 0, 0, 1) 70%
            );
            -webkit-mask-size: 200%;
            // animation: shine 2s infinite;
          }
          &::after {
            position: absolute;
            content: '';
            background: $white;
            width: 6px;
            height: 3px;
            right: 32%;
            bottom: 25px;
            transition: all 300ms ease;
          }
        }
      }
    }
    @media (min-width: 992px){
    .language-toggle {
      position: absolute;
      right: -20px;
      button {
        color: #000;
        background-color: #ffffff;
        border-color: transparent;
        // border-color: #222222;
      }
    }
  }
  @media (min-width: 681px) and (max-width: 991px){
    .language-toggle {
     position: absolute;
      button {
        padding: 3px 3px;
        color: #000;
        background-color: #ffffff;
        
        border-color: transparent;
        // border-color: #222222;
      }
    }
  }
  @media (max-width: 680px){
    .language-toggle {
     position: absolute;
      button {
        padding: 3px 3px;
        color: #000;
        background-color: #ffffff;
        text-overflow: clip;
        overflow: hidden;
        width: 80px;
        border-color: transparent;
        // border-color: #222222;
      }
    }
  }
    .dropdown {
      .sub-dropdown {
        .sub-dropdown-menu {
          top: 20%;
          left: 100%;
          li {
            a {
              color: $secondary !important;
              .sub-squre {
                color: $primary !important;
                font-size: 7px;
                top: -1px;
                transition: all 0.3s;
              }
            }
            &:hover {
              a,
              .sub-squre {
                color: $white !important;
              }
            }
          }
        }
        &:hover {
          .sub-dropdown-menu {
            display: block !important;
          }
        }
      }

      &:hover {
        .dropdown-menu {
          display: block;
        }
      }
    }
  }
  .content-menu {
    border-top: 3px solid $primary;
    padding: 0;
    margin: 0;
    li {
      transition: all 0.3s;
      a {
        padding: 11px 0px 11px 20px !important;
        color: $secondary !important;
        transition: all 0.3s;
        .squre {
          color: $primary;
          font-size: 7px;
          top: -1px;
          transition: all 0.3s;
        }
      }
      &:hover {
        background: $primary;
        a,
        svg {
          color: $white !important;
        }
      }
    }
  }
  .user-profile {
    .dropdown-menu {
      transform: translate3d(0px, 75px, 0px) !important;
      @media (min-width: 576px) and (max-width: 767.98px) {
        width: 0px !important;
      }
      @media (min-width: 880px) and (max-width: 991.98px) {
        transform: translate3d(71px, 75px, 0px) !important;
      }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        transform: translate3d(-94px, 76px, 0px) !important;
        width: 0px !important;
      }
      @media (min-width: 1200px) {
        transform: translate3d(-18px, 75px, 0px) !important;
        width: 215px !important;
      }
      .nav-item {
        line-height: 1.5 !important;
      }
    }
  }
  &.sticky-header {
    background: $white;
    box-shadow: 0 5px 15px rgba($color: #000000, $alpha: 0.15) !important;
  }
}

.menu-container {
  --siteMenuArrowOffset: 0;
  --siteMenuRotateX: 0;
  --siteMenuBoxHeight: 0;
  --siteMenuBoxWidth: 0;
  display: block;
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  perspective: 2000px;
  overflow: visible;
  opacity: 0;
  transition: 500ms;
  transition-property: opacity;
  transition-property: opacity;
  transition-property: opacity;
  will-change: opacity;
  [hidden] {
    pointer-events: none;
    display: block !important;
  }
  .site-arrow {
    position: absolute;
    top: 8px;
    left: 50%;
    margin: 0 0 0 -6px;
    width: 12px;
    height: 12px;
    opacity: 0;
    border-radius: 3px 0 0 0;
    background: $white;
    transform: translateY(-9px) translateX(var(--siteMenuArrowOffset))
      translateX(-20px) rotate(45deg);
    box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
    transition: 250ms;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    will-change: transform, opacity;
    z-index: 2;
  }
  .menu-area {
    position: absolute;
    top: 5px;
    left: 50%;
    display: inline-block;
    min-width: 100px;
    min-height: 72px;
    z-index: 3;
    height: var(--siteMenuBoxHeight);
    width: var(--siteMenuBoxWidth);
    transform: translateX(-50%) rotateX(-30deg)
      translateX(var(--siteMenuArrowOffset));
    -webkit-transform-origin: 50% -50px;
    transform-origin: 50% -50px;
    transition: 250ms;
    transition-property: width, height, -webkit-transform;
    transition-property: transform, width, height;
    transition-property: transform, width, height, -webkit-transform;
    will-change: transform, width, height;
    .menu-card {
      position: relative;
      min-width: 100px;
      min-height: 72px;
      height: 100%;
      border-radius: 8px;
      background: $white;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3);
      overflow: hidden;
      .menu-item {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        transform: translateX(0);
        transition: 250ms;
        -webkit-transition-property: opacity, -webkit-transform;
        transition-property: opacity, -webkit-transform;
        transition-property: transform, opacity;
        transition-property: transform, opacity, -webkit-transform;
        will-change: transform, opacity;
        &.menu-item-left {
          transform: translateX(150px);
        }
        &.menu-item-right {
          transform: translateX(-150px);
        }
        &[hidden] {
          opacity: 0;
        }
      }
    }
  }
}
.menu-container-visable {
  .menu-container {
    opacity: 1;
    .site-arrow {
      opacity: 1;
    }
    .menu-area {
      transform: translateX(-50%) rotateX(0)
        translateX(var(--siteMenuArrowOffset));
      pointer-events: auto;
    }
    &.full-menu-content {
      .menu-area {
        width: 100%;
        transform: translateX(-50%) rotateX(0);
      }
    }
  }
}
.school_header_truncate{
  &_name{
    width: 155px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // transition: 0.3s;
    // :hover{
    //   background-color: #7863fe;
    // }
  }
}

