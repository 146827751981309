.student-form-all {
  background-color: #f5f5f5;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  min-height: 220px;

  .course-side-clr {
    background-color: #FDA520;
    cursor: pointer;
    color: white;
    // margin-left: auto;
    // margin-right: -28px;
    position: fixed;
    right: 0px;
    z-index: 99;
  }

  // .course-side-clr:hover{
  //   margin-right: -28px;
  //   transition: margin-right 1s;
  //   transition-timing-function: linear
  // }

  .react-phone-form {
    @include phone-number-outline;
  }

  .form-control {
    @include space-after-form-input(0rem);
  }
}

@media (max-width: 576px) {
  .student-form-all {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .rowOutline {
    &--wrapper {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .form-content {
    padding: 0 !important;
  }
}

.student-form {
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    // color: black !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #f5f5f5 !important;
  }

  .headerclr {
    background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
    padding: 10px;
    color: white;
  }

  .rowOutline {
    &--wrapper {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    border: 1px solid lightgray;
    border-radius: 5px;
  }

  .student-doc-header {
    background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
    color: white;
    font-size: 16px;
    height: 45px;
    padding: 9px 10px;
  }

  .formInner {
    padding: 20px;
    margin: 3px;

    .sponsor_form_margin_bottom {
      margin-bottom: 16px
    }

  }

  .form-control {
    @include space-after-form-input(0rem);
  }

  .form-label {
    margin-bottom: 0.3rem !important;
    color: black;
  }

  input,
  select {
    padding: 0.5rem 0.5rem !important;
    font-size: 0.9rem !important;
  }

  .grid-container {
    // display: grid;
    // grid-template-columns: auto auto auto auto auto auto;
    // grid-gap: 10px;
    // padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    div {
      margin-right: 1.5rem;
      padding: 20px 0;
      font-size: small;
      color: $page-color;
    }
  }

  .lCircle {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto;
    position: relative;
    object-fit: cover;
  }

  .sCircle {
    background: #f5f5f5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translate(64px, -34px);
  }

  .edit-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #eeaa11;
  }

  #myTabContent .content>div {
    background-color: white;
  }

  .link-content {
    border: 1px solid #f5f5f5;
    padding: 20px;
    color: #fff;
    background-color: $page-color;
  }

  .form-content {
    // style={{backgroundColor:'white', marginLeft: '-15px', marginRight:'-15px', paddingLeft:'30px', paddingRight:'30px'}}
    background-color: #fff;
    margin: 0 -15px;
    padding: 0 30px;
  }

  // Admission Application
  .content {
    div {
      ul {
        justify-content: center;
        margin: 35px auto;
      }

      li {
        border: 1px solid #707070;
        color: white;
      }
    }
  }

  // .nav-link1 {
  //   color: white;
  //   background-color: $page-color !important;
  //   padding: 5px 25px;
  //   &:active {
  //     background-color: $page-color !important;
  //   }
  // }
}

.nav-link1 {
  width: 90px;
  height: 40px;
  background-color: white;
}