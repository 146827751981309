.form-validation-message{
    margin-bottom: 1rem;
}
.school-form-bottom-gap{
    margin-bottom: 0px !important;
}
.form-validation-invalid-msg{
    border: 1px solid red !important;
}
.form-validation-valid-msg{
    border: 1px solid #ced4da !important;
}