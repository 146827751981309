.application-detail {
  padding: 20px;
  margin-top: 30px;
  &-image {
    width: 230px;
    height: 230px;
    object-fit: cover;
  }
  .info-section {
    padding: 20px;
  }
  &-heading {
    margin-bottom: 40px;
    font-size: 31px !important;
    &-name {
      background: #5575df;
      padding: 4px 20px;
      border-radius: 40px;
      color: white;
    }
    span {
      margin-right: 40px;
    }
  }
  &-course {
    div {
      margin-bottom: 10px;
    }
  }
  &-profile {
    margin-bottom: 10px;
    &-first {
      div {
        margin-bottom: 8px;
        margin-right: 20px;
      }
    }
    &-second {
      div {
        margin-bottom: 8px;
        margin-right: 20px;
      }
    }
  }
  &-button {
    border-radius: 58px;
    padding: 0px 22px;
    // color: #5575df;
    color: #5575df;
    // border-color: #5575df;
    border-color: #5575df !important;
    background-color: #fff;
  }
}

.active-tab {
  background-color: #fff;
  border: none;
}
.unactive-tab {
  border: none;
  background-color: #c7c7c7;
  color: #fff;
}

.box {
  .container {
    // width: 100%;
    // height: 357px;

    .lines {
      .blur{
        .dot {
        width: 14px;
        height: 14px;
        background: #d1d6e6;
        border-radius: 7px;
      }
      .line {
        height: 33px;
        width: 2px;
        background: #d1d6e6;
        margin-left: 5.3px;
      }}
      .unblur{
        .dot {
          width: 14px;
          height: 14px;
          background: #5575df;
          border-radius: 7px;
        }
      .line {
        height: 33px;
        width: 2px;
        background: #5575df;
        margin-left: 5.3px;
      }
      }
      
    }

    .cards {
      margin-left: 12px;
      transform: translateY(-20px);
      .card {
        //width: 249px;
        // height: 93px;
        padding-top: 16px;
        background: #ffffff;
        border: none !important;
        margin-bottom: -12px;
        p{
          margin-bottom: 19px;
        }
      }
    }
  }
}
.highlight{
 font-weight: bold;

}
.blur{
  opacity: 0.5;
}

