// $primary: $default;

// @import './variant/variables';

// body[data-theme='default'] {
//   @import './variant/variant';
// }

// $primary: $default;
$primary: $default;

@import "./variant/variables";

body[data-theme="default"] {
  @import "./variant/variant";

  .outline-hover--effect {
    text-transform: capitalize;
    transition: all 0.4s ease-in-out;
    .icon {
      transition: all 0.4s ease-in-out;
    }
    &::before {
      display: none;
    }
    &:hover {
      color: $white !important;
      border-color: transparent;
      background-color: transparent;
      .icon {
        color: $white !important;
      }
    }
  }

  .default-hover--effect {
    transition: all 0.4s ease-in-out;
    &::before {
      display: none;
    }
    &:hover {
      color: $white !important;
      border-color: transparent;
    }
  }

  .btn-closed {
    background-color: #e6392c;
    color: $white;
    background-size: 300% 100%;
    border-radius: 50px !important;
    transition: all 0.4s ease-in-out;
    border-color: transparent;
    a {
      color: $white;
    }
    &:hover {
      background-position: 100% 0 !important;
      color: $white;
    }
    &:disabled {
      background-color: #e6392c;
    }
  }

  .btn-outline-primary {
    background-size: 300% 100%;
    border-radius: 50px !important;
    transition: all 0.4s ease-in-out;
    &:hover {
      background-image: linear-gradient(
        to right,
        #10a9ac,
        #5575df,
        // #4c46e6,
        // rgb(151, 0, 255)
      );
    }
  }

  .btn-primary {
    background-image: linear-gradient(
      to right,
      #10a9ac,
      #5575df,
      // #4c46e6,
      // rgb(151, 0, 255)
    );
    color: $white;
    background-size: 300% 100%;
    border-radius: 50px !important;
    transition: all 0.4s ease-in-out;
    border-color: transparent;
    a {
      color: $white;
    }
    &:hover {
      background-position: 100% 0 !important;
      color: $white;
    }
  }

  .bg-primary {
    background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
    border-color: transparent;
  }

  .job-category {
    &:hover {
      background-image: linear-gradient(
        to right,
        #10a9ac,
        #5575df,
        // #4c46e6,
        // rgb(151, 0, 255)
      );
      background-size: 300% 100%;
    }
  }

  .breadcrumbs {
    background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
  }

  header {
    background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
    border-color: transparent !important;
    box-shadow: 0 5px 10px rgba($color: $black, $alpha: 0.1);
    .navigation {
      background: transparent;
      .nav-item {
        .main-nav {
          color: $white !important;
          &:hover,
          &.active {
            color: $white !important;
            &::before {
              background: $white;
            }
            &::after {
              // background: $primary;
              background: $white;
            }
          }
        }
      }
      .search-button {
        button {
          background: transparent !important;
          color: $white !important;
        }
      }
    }
    .user-profile {
      h5,
      .media-body {
        color: $white !important;
      }
    }
    .content-menu {
      border-top: 3px solid $white !important;
      li {
        &:hover {
          background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
        }
      }
    }
    .user-info {
      .btn-secondary {
        background: transparent !important;
        color: $white !important;
        font-size: 20px;
      }
    }
    .site-arrow {
      background: $white !important;
    }
  }

  .best-jobs {
    .best-jobs-tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            &.active {
              background-image: linear-gradient(
                90deg,
                #10a9ac 25%,
                #5575df 100%
              ) !important;
            }
          }
        }
      }
    }
  }

  .hyper-link {
    color: #5476de !important;
    text-decoration: underline !important;
    &:hover {
      color: #5476de !important;
      text-decoration: underline !important;
    }
  }

  .loading-spinner {
    color: $green-green;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
  }

  .center {
    // position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
  }

  .no-result-text {
    font-size: 18px !important;
    color: #747474 !important;
    margin: auto !important;
  }

  .select-dropdown {
    input {
      width: 200px !important;
    }
  }

  .language-dropdown-menubar {
    background: #10a9ac !important;
  }
}
