@mixin student-row {
  display: flex;
  flex-direction: column;
}

@mixin student-all-align-content {
  background-color: $white;
  padding-left: 50px;
  padding-right: 50px;
}

.std-bkg-theme {
  background-color: #f5f5f5;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 575px) {
  .std-bkg-theme {
    background-color: #f5f5f5;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.li-data {
  position: fixed;

  li {
    font-size: medium;
    margin-bottom: 15px;
    color: $page-color  !important;
  }
}

.btn-mid {
  text-align: center;

  button {
    width: 70%;
    padding: 5px;
    margin: 10px;
  }
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color: black !important;
}

.nav-tabs {
  border-bottom: 1px solid #f5f5f5 !important;
}

.headerclr {
  background-image: linear-gradient(90deg, #10a9ac 25%, #5575df 100%);
  padding: 10px;
  color: white;
}

.formInner {
  padding: 20px;
}

.form-control {
  @include space-after-form-input(1rem);
}

.form-label {
  margin-bottom: 0.3rem !important;
  color: black;
}

.grid-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  div {
    margin-right: 1.5rem;
    padding: 20px 0;
    font-size: small;
    color: $page-color;
  }
}

.lCircle {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
  position: relative;
  object-fit: cover;
}

.sCircle {
  background: #f5f5f5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  transform: translate(64px, -34px);
}

.edit-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #eeaa11;
}

#myTabContent .content>div {
  background-color: white;
}

.link-content {
  border: 1px solid #f5f5f5;
  padding: 20px;
  color: #fff;
  background-color: $page-color;
}

.form-content {
  background-color: #fff;
  margin: 0 -15px;
  padding: 0 30px;
}

//   Form
// Add and close part of form
.form-add-trans {
  transform: translateY(15px);
  text-align: center;
}

.form-close-trans {
  transform: translate(10px, 5px);
  text-align: end;
}

.title {
  span {
    display: block;
  }
}

.student-profile-content {
  div {
    margin: 10px;
  }
}

.student-preference-content {
  flex-wrap: wrap;
  margin-top: 20px;

  div {
    background-color: #f5f5f5;
    padding: 8px;
    margin: 10px 3px;
    border-radius: 8px;
  }
}

.student-font-icon {
  height: 200px;
  width: 200px;
  opacity: 0.2;
}

.std-align-content {
  @include student-all-align-content;
}

@media only screen and (max-width: 600px) {
  .std-align-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.student-progress {
  @include student-row;
}

.student-progress-align {
  @include student-row;
  text-align: end;
  justify-content: space-around;
  position: relative;

  &-review {
    width: fit-content;
    padding: 5px 15px;
    position: inherit;
    transform: translate(275px, 0px);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1220px) {
  .student-progress-align {
    &-review {
      transform: translate(184px, 0px);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .student-progress-align {
    &-review {
      transform: translate(63px, 0px);
    }
  }
}

@media only screen and (max-width: 770px) {
  .student-progress-align {
    &-review {
      transform: translate(9px, 0px);
    }
  }
}

@media only screen and (max-width: 579px) {
  .student-progress-align {
    &-review {
      transform: translate(0px, 0px);
    }
  }
}

//relatives documents

@media only screen and (max-width: 576px) {
  .student-progress-align {
    @include student-row;
    text-align: start;
    justify-content: space-around;
    padding: 1rem;
  }
}

.std-progress-gap {
  div {
    margin-right: 15px;
  }
}

.std-img-rel {
  position: relative;
}

.std-img-abs-topcen {
  position: absolute;
  top: 15%;
  font-size: large;
  left: 25%;
}

.std-img-abs-cen {
  position: absolute;
  top: 38%;
  left: 33%;
  font-size: 70px;
}

.app-admsn-form {
  .form-section {
    padding: 1rem 1rem 0 1rem !important;
  }

  .section-heading {
    font-size: 18px !important;
    font-weight: 400 !important;
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    // font-weight: bold !important;
  }

  .title-label {
    font-size: 14px !important;
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    color: #10a9ac !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
  }

  .info-label {
    font-size: 18px !important;
    // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    color: #5b5b5b !important;
    font-weight: 400 !important;
    margin-bottom: 1rem !important;
  }
}