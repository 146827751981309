// .school-pin-list{
//   .icon {
//     color: $green-green !important;
//     font-size: 70px;
//   }
//   .firstColmn{
//     border: 1px solid #707070;
//     border-radius: 10px;
//     padding: 15px;
//   }

// }
.course-item, .school-pin-list{
  color: #707070;

  &-pin{
    position: absolute;
    right: 30px;
  }

  @media (max-width: 560px) {
    &-pin{
      position: absolute;
      right: 0px;
    }
  }

  .icon {
    color: $green-green !important;
    font-size: 70px;
  }
  .firstColmn{
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 15px;
  }

  .title {
    font-size: 25px !important;
    // &:hover {
    //   text-decoration: underline !important;
    // }
  }
  @media (max-width: 576px) {
    .course-detail-item-mrg {
      padding: 0px !important;
      margin: 0px !important;
      box-sizing: border-box !important;
    }
  }

  .school-title {
    font-size: 22px !important;
    // &:hover {
    //   text-decoration: underline !important;
    // }
  }

  .third-heading {
    display: inline;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    background: #f6f6f6;
    border: #e2e2e2;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 400;
    color: #7e7d7d !important;
  }

  .fourth-heading {
    display: flex;
    div {
      margin-right: 20px;
    }
    .title {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
    .info {
      display: inline;
    padding-left: 8px;
    padding-right: 8px;
    // padding-top: 3px;
    padding-bottom: 3px;
    background: #f6f6f6;
    border: #e2e2e2;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 400;
    color: #7e7d7d !important;
    }
  }

  .detail-title {
    font-size: 30px;
    color: #707070;
  }
  .course-item-hr-line {
    margin-left: -34px;
    margin-right: -33px;
  }
  .course-item-apply-button {
    float: right;
  }

  // @media (max-width: 382px) {
  //   margin-left: -30px;
  //   margin-right: -20px;
  // }

  @media (max-width: 991px) {
    .detail-title {
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      margin-top: 15px;
    }
    .fourth-heading {
      display: block;
    }
  }
  @media (max-width: 587px) {
    .course-item-apply-button {
      float: left;
      margin-left: -33px !important;
      padding-left: 20px;
      padding-right: 20px;
    }
    .course-item-align-date {
      margin-right: -20px;
    }
  }
  @media (max-width: 380px) {
    .course-item-sm-padding {
      padding: 0px 40px;
    }
  }
  .detail-overview-title {
    font-weight: 550 !important;
    font-size: 16px !important;
    color: $green-green;
  }

  .detail-overview-info {
    font-weight: 400 !important;
    font-size: 13px !important;
  }

  .location {
    font-weight: 275 !important;
  }

  .description {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 1.8;
  }

  .detail-description {
    font-weight: 400 !important;
    font-size: 15px !important;
  }

  .info-title {
    font-weight: 550 !important;
    font-size: 14px !important;
  }

  .info-desc-tag {
    display: inline;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    background: #f6f6f6;
    border: #e2e2e2;
    border-radius: 4px;
    font-size: 14px !important;
    color: #7e7d7d !important;
  }

  .detail-tag-title {
    font-weight: bold !important;
    font-size: 14px !important;
  }

  .detail-card {
    .heading {
      font-weight: 400 !important;
      font-size: 18px !important;
    }

    .title {
      font-weight: 400 !important;
      font-size: 14px !important;
      text-decoration: none !important;
      color: $green-green;
    }

    // .info {
    //   font-weight: 300 !important;
    //   font-size: 14px !important;
    // }
    .info {
      font-weight: 400 !important;
      font-size: 18px !important;
    }
  }

  .btn-apply {
    background: $green-green !important;
    border: none;
    border-radius: 25px;
  }
}
@media (max-width: 991px) {
  .course-item {
    .school-title {
      //text-align: center;
      font-size: 15px !important;
    }
  }
}
.course-item-media {
  display: block;
  flex-direction: row;
}

// Course Create
.course_create_margin{
  div{
    margin-bottom: 10px;
  }
}