.background-outline {
  @include background-outline;
}

.background-inline {
  @include background-inline;
}

.headercolor {
  @include headerclr;
}
.agent-align-content {
  @include agent-align-content;
}
.large-circle {
  @include lCircle;
}
.agent-chart {
  border: 3px solid #f5f5f5;
  border-radius: 5px;
  div {
    div {
      text-align: center;
    }
  }
}
.agent-application-chart {
  border: 3px solid #f5f5f5;
  border-radius: 5px;
}
.agent-chart-outline {
  div {
    div {
      padding-right: 10px;
    }
  }
}
.agent-left-side {
  padding-left: 0px !important;
  &-highlight {
    background-color: lightblue;
    padding: 0px 22px;
    border-radius: 15px;
    margin-left: 15px;
  }
}
