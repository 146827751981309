.hero-banner--three {
  display: block;
  &:hover {
    .react-multiple-carousel__arrow {
      display: block;
      &--left {
        left: calc(4% + 1px);
        transition: all 0.8s;
      }
      &--right {
        right: calc(4% + 1px);
        transition: all 0.8s;
      }
      &:hover {
        // background: $blue-violet;
        // border: 1px solid $blue-violet;
        background: $green-green;
        border: 1px solid $green-green;
        &::before {
          color: $white !important;
        }
      }
    }
  }
  .hero-banner--bg {
    left: -10%;
    bottom: 0;
  }
  .round-shape1 {
    right: -100px;
    top: -124px;
    z-index: -1;
  }
  .round-shape2 {
    bottom: -125px;
    left: -140px;
    z-index: -1;
  }
  .bubble-1 {
    position: absolute;
    top: 86px;
    left: 23px;
  }
  .bubble-2 {
    position: absolute;
    top: 50px;
  }
  .bubble-3 {
    position: absolute;
    bottom: 38%;
  }
  .react-multiple-carousel__arrow {
    display: none;
    outline: none;
    &--left {
      position: absolute;
      left: -138px;
      // background: #f6edff;
      // border: 1px solid #d4bdff;
      background: #ffffff;
      border: 1px solid #707070;
      transition: all 0.8s;
    }
    &--right {
      position: absolute;
      right: -138px;
      // background: #f6edff;
      // border: 1px solid #d4bdff;
      background: #ffffff;
      border: 1px solid #707070;
      transition: all 0.8s;
    }
    &::before {
      // color: #d4bdff !important;
      color: #707070 !important;
    }
  }
  .hero-banner--content {
    // padding-top: 175px;
    // padding-bottom: 180px;
    width: 70%;
    margin-top: 115px;
    margin-right: 65px;
    float: right;
    // margin-bottom: 180px;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 22px;
    padding-right: 22px;
    // padding-top: 217px;
    // padding-bottom: 250px;
    // height: 850px!important;
    background: #70707090 !important;
    //opacity: 60% !important;
    @media (max-width: 991px) {
      width: 100%;
      margin-top: 40px;
      margin-right: 0px;
      // margin-bottom: 40px;
      padding: 20px 20px 20px 20px;
      // background: #ffffff !important;
      // opacity: 100% !important;
      //background: rgba($color: #fff, $alpha: 1.0);
      background-color: rgba($color: #fff, $alpha: 1);
      text-align: center !important;
      .content-icon {
        ul {
          justify-content: center !important;
          margin-left: 50px !important;
        }
      }
    }
    @media (max-width: 424px) {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      // margin-bottom: 40px;
      padding: 30px 30px 30px 30px;
      background-color: rgba($color: #fff, $alpha: 1);
      // padding: 100px 0;
      h1 {
        font-size: 40px !important;
      }
    }
    .heading {
      // color: #393939;
      color: #ffffff;
      font-size: 55px;
      font-weight: 400;
      padding-bottom: 20px;
      // animation-delay: 1.5s;
      animation-delay: 0.6s;
      // text-transform: capitalize;
      line-height: 57px;
      span {
        font-weight: 600;
      }
      @media (max-width: 991px) {
        color: #393939;
      }
      @media (max-width: 424px) {
        color: #393939;
      }
    }

    p {
      // animation-delay: 2s;
      animation-delay: 1s;
      // color: #393939;
      color: #ffffff;
      padding-bottom: 30px;
      @media (max-width: 991px) {
        color: #393939;
      }
      @media (max-width: 424px) {
        color: #393939;
      }
    }
  }
  .hero-banner--content-new1 {
    margin-top: 10px;
    margin-right: 32px;
    float: right;
    padding: 22px;
    background: #70707090 !important;
    @media (max-width: 1105px) {
      background: #70707090 !important;
    }
    @media (max-width: 1270px) and (min-width: 2189px) {
      float: right;
      margin-top: 500px;
      margin-right: -760px;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 1170px) and (max-width: 1270px) {
      float: right;
      margin-top: 455px;
      margin-right: -524px;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 1150px) and (max-width: 1170px) {
      float: right;
      margin-top: 450px;
      margin-right: -520px;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 991px) and (max-width: 1000px) {
      float: right;
      margin-top: 400px;
      margin-right: -438px;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 1000px) and (max-width: 1008px) {
      float: right;
      margin-top: 410px;
      margin-right: -438px;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 1008px) and (max-width: 1150px) {
      float: right;
      margin-top: 416px;
      margin-right: -450px;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 100px)  and (max-width: 499px) {
      margin-top: -515px;
      margin-right: -10px;
      float: right;
    }
  }
  .hero-banner--content-new1 {
    @media (min-width: 500px) and (max-width: 991px) {
      margin-top: -415px;
      margin-right: -5px;
      float: right;
    }
  }
  .hero-banner--content-new {
    @media (min-width: 1852px) {
      margin-top: 100px;
      margin-right: 32px;
      margin-left: auto !important;
      width: fit-content;
      /* float: right; */
      padding: 22px;
      background: #70707090 !important;
    }
  }
  .hero-banner--content-new {
    @media (max-width: 991px) {
      margin-top: 100px;
      margin-bottom: 15px;
      margin-right: 32px;
      margin-left: auto !important;
      width: fit-content;
      padding: 22px;
      background-color: white !important;
    }
  }
  .hero-banner--content-new {
    margin-top: 300px;
    margin-left: auto !important;
    width: fit-content;
    margin-right: 32px;
    padding: 22px;
    background: #70707090;
    // opacity: 60% !important;
    @media (min-width: 991px) and (max-width: 1148px) {
      margin-top: 40px;
      margin-left: -75px;
      margin-right: 32px;
      float: right;
      padding: 22px;
      background: #70707090 !important;
    }
    @media (min-width: 1148px) and (max-width: 1270px) {
      float: right;
      margin-top: 140px;
    }
    @media (max-width: 991px) {
      width: 100%;
      margin-top: 40px;
      margin-right: 0px;
      // margin-bottom: 40px;
      padding: 20px 20px 20px 20px;
      background-color: rgba($color: #fff, $alpha: 1);
      text-align: center !important;
      .content-icon {
        ul {
          justify-content: center !important;
          margin-left: 50px !important;
        }
      }
    }
    @media (max-width: 424px) {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      // margin-bottom: 40px;
      padding: 30px 30px 30px 30px;
      background-color: rgba($color: #fff, $alpha: 1);
      // padding: 100px 0;
      h1 {
        font-size: 40px !important;
      }
    }
    .heading {
      // color: #393939;
      color: #ffffff;
      font-size: 48px;
      font-weight: 400;
      padding-bottom: 20px;
      // animation-delay: 1.5s;
      animation-delay: 0.6s;
      // text-transform: capitalize;
      line-height: 57px;
      span {
        font-weight: 600;
      }
      @media (max-width: 991px) {
        color: #393939;
      }
      @media (max-width: 424px) {
        color: #393939;
      }
    }

    .subheading {
      // color: #393939;
      color: #ffffff;
      font-size: 30px;
      font-weight: 400;
      padding-bottom: 20px;
      // animation-delay: 1.5s;
      animation-delay: 0.6s;
      // text-transform: capitalize;
      line-height: 57px;
      span {
        font-weight: 600;
      }
      @media (max-width: 991px) {
        color: #393939;
      }
      @media (max-width: 424px) {
        color: #393939;
      }
    }
    .action-btn {
      font-size: 23px;
    }
    p {
      // animation-delay: 2s;
      animation-delay: 1s;
      // color: #393939;
      color: #ffffff;
      font-size: 17px;
      padding-bottom: 20px;
      @media (max-width: 991px) {
        color: #393939;
      }
      @media (max-width: 424px) {
        color: #393939;
      }
    }
  }
  .banner-new {
    // position: relative!important;
    // width: 100%;
    // display: grid !important;
    // justify-items: start;

    // /* Set vertical alignment of items, in case they have a different height. */
    // /* align-items: start | end | center | stretch (default); */
    // align-items: start;

    // // grid-template-columns: repeat(2, 1fr);

    // // width: 100%;

    // width: 100%;
    // z-index: 0;
    // position: absolute;
    // top: 15%;
    // right: -216px;
    // right: -100px;
    // right: -90%;
    // @media (max-width: 991px) {
    //   display: none !important;
    // }
    // @media (max-width: 424px) {
    //   display: none !important;
    // }

    // .image {
    //   position: absolute!important;
    //   top: 80px;
    //   right: 0;
    // }
  }

  .banner-new-image {
    position: relative;
    @media (max-width: 991px) {
      display: none !important;
    }
    @media (max-width: 424px) {
      display: none !important;
    }
    // position: absolute!important;
    // top: 80px;
    // right: 0;
  }

  // .banner-new > * {
  //   grid-column-start: 1 !important;
  //   grid-row-start: 1 !important;
  // }

  // #banner-new-content {
  //   margin-right: 10px !important;
  //   width: 50% !important;
  //   color: brown;
  // }
}
