.school-item {
  color: #707070;

  .icon {
    color: $green-green !important;
    font-size: 50px;
  }

  .title {
    font-size: 25px;
    // &:hover {
    //   text-decoration: underline !important;
    // }
  }

  .detail-title {
    font-size: 30px;
    color: #707070;
  }

  .detail-overview-title {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: $green-green;
  }

  .detail-overview-info {
    font-weight: 400 !important;
    font-size: 18px !important;
  }

  .location {
    font-weight: 275 !important;
  }

  .description {
    font-weight: 400 !important;
    font-size: 12px !important;
  }

  .detail-description {
    font-weight: 400 !important;
    font-size: 15px !important;
  }

  .info-title {
    font-weight: bold !important;
    font-size: 14px !important;
  }

  .info-desc-tag {
    display: inline;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    background: #f6f6f6;
    border: #e2e2e2;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 400;
    color: #7e7d7d !important;
  }

  .detail-card {
    .heading {
      font-weight: 400 !important;
      font-size: 18px !important;
    }

    .title {
      font-weight: 400 !important;
      font-size: 14px !important;
      text-decoration: none !important;
      color: $green-green;
    }

    .info {
      font-weight: 400 !important;
      font-size: 18px !important;
    }
  }
}
