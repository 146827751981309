.react-multi-carousel-dot {
  button {
    border-color: transparent !important;
    background: $white !important;
    margin-bottom: 20px !important;
  }
}
.react-multi-carousel-dot--active {
  button {
    background: $primary !important;
  }
}
