//
// fonts
//
@import './fonts';

//
// Custom functions
//

@import './functions';

//
// Custom variables
//

@import './custom-variables';

//
// Bootstrap override  variables
//

@import './variables';

//
// Custom mixins
//

@import './mixins';

//
//  Bootstrap frameworks
//

@import '~bootstrap/scss/bootstrap.scss';

// reboot

@import './reboot';

//
// Components
//

@import './components';

//
// Utilities
//

@import './utilities';

//
// Themeing variant colors
//

@import './theming';


.cursor-hover:hover {
  cursor: pointer;
}

.object-fit {
  &-cover {
    object-fit: cover;
  }

  &-contain {
    object-fit: contain;
  }
}

img {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width:769px) {

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 100%;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .school-scrollbar::-webkit-scrollbar-thumb {
    background: #7634a8;
  }

  .student-scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #10a9ac 25%, #5575df 100%);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}


img {
  height: 100%;
  width: 100%;
}


// PSEUDOS
.after-none::after {
  display: none;
}

// BG-COLORS
.bg-gray {
  background-color: #e9ecef;
}



#notification {
  .dropdown-menu {
    min-width: 320px;
  }

  .dropdown-item {
    white-space: normal;
  }

  @media screen and (min-width:600px) {
    .dropdown-menu {
      min-width: 600px;
    }
  }

}

.modal-header {
  button {
    span {
      font-size: 20px;
      color: #000;
      padding: 0px 7px;
      border-radius: 50%;
      background-color: #fff
    }
  }
}