.single-job {
  a {
    transition: all 0.6s;
  }
  &:hover {
    a {
      transition: all 0.6s;
      color: $primary;
    }
  }
}
