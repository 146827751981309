.top-jobs--wrapper {
  .top-jobs {
    transition: all 0.6s;
    transform: translateY(-50%);
    &:hover {
      margin-top: -10px;
      transition: all 0.6s;
    }
  }
}

@media (max-width: 767px) {
  .top-jobs--wrapper {
    .row {
      padding: 48px 0;
    }
    .top-jobs {
      transform: translateY(0);
    }
  }
}
