.job-category {
  transition: all 0.6s;
  &::before {
    content: '';
    width: 600px;
    height: 600px;
    position: absolute;
    bottom: 38%;
    left: 50%;
    background-color: rgba(245, 245, 245, 0.15);
    border-radius: 50%;
    transform: translate(-50%, -100%);
    z-index: 8;
    transition: all 0.6s;
  }
  .hover-block {
    &::before {
      content: '';
      width: 600px;
      height: 600px;
      position: absolute;
      bottom: 71%;
      left: 50%;
      background-color: rgba(255, 255, 255, 0.09);
      border-radius: 50%;
      transform: translate(-50%, -100%);
      z-index: 10;
      transition: all 0.6s;
    }
    &::after {
      content: '';
      width: 600px;
      height: 600px;
      border-radius: 50%;
      position: absolute;
    }
  }
  &:hover {
    background: $primary;
    border: 1px solid $primary;
    transition: all 0.6s;
    &::before {
      transform: translate(-50%, 0%);
      transition-delay: 0.2s;
      transition: all 0.6s;
    }
    .hover-block {
      &::before {
        transform: translate(-50%, 0%);
        transition-delay: 0.1s;
        transition: all 0.6s;
      }
      &::after {
        transform: translate(-50%, 0%);
        transition: all 0.6s;
      }
    }
    .icon {
      color: $gray-200 !important;
    }
    h5 {
      color: $gray-200 !important;
    }
    .card-text {
      color: $gray-200 !important;
    }
  }
}
