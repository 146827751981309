/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding-top: 110px;
  z-index: 67;
  &-school-top-inner {
    background-color: rgba(77, 173, 175, 0.2);
    &-drpdwn {
      div {
        button {
          border-radius: 5px;
        }
      }
    }
    img {
      float: right;
      height: 40px;
      margin: -12px -12px 0px 0px;
    }
    h5 {
      padding: 40px 0px;
    }
    &-btn {
      padding: 200px 0px 20px 0px;
      button {
        background-color: #4dadaf !important;
        color: white;
        padding: 10px 20px;
        border-radius: 30px;
      }
    }
  }
}

// .box {
//   position: relative;
//   width: fit-content;
//   margin: 0 auto;
//   height: auto;
//   max-height: 70vh;
// //   margin-top: calc(100vh - 85vh - 20px);
// margin-top: 330px;
//   background: #fff;
//   border-radius: 4px;
//   padding: 20px;
//   border: 1px solid #999;
//   overflow: auto;
// }

// .close-icon {
//   content: 'x';
//   cursor: pointer;
//   position: fixed;
//   right: calc(15% - 30px);
//   top: calc(100vh - 85vh - 33px);
//   background: #ededed;
//   width: 25px;
//   height: 25px;
//   border-radius: 50%;
//   line-height: 20px;
//   text-align: center;
//   border: 1px solid #999;
//   font-size: 20px;
// }
