.searchby-category {
  margin-left: 220px;
  margin-right: 220px;
  @media (max-width: 991.98px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  form {
    border-radius: 10px;
    @media (max-width: 991.98px) {
      border-radius: 10px;
    }
    .btn {
      border-radius: 10px;
      // background: $blue-violet-gradient;
      // border-color: $blue-violet;
      background: $green-green-gradient;
      border-color: $green-green !important;

      @media (max-width: 991.98px) {
        border-radius: 0px;
      }
    }
    .form-group {
      @media (max-width: 991.98px) {
        padding: 10px 16px !important;
        border-bottom: 1px solid $gray-300 !important;
        &.keyword {
          border-bottom: 0 !important;
        }
      }
      .form-control {
        @media (max-width: 991.98px) {
          padding-left: 20px !important;
          border-right: 0 !important;
        }
      }
    }
  }
}
