footer {
  position: relative;
  background: #303d59;
  // clear: both;
  // height: 330px;
  // margin-top: 279px;
  .foo-overview {
    position: relative;
    z-index: 1000;
    margin: 0 0 30px -18px;
    .card-body {
      padding-bottom: 0;
      p {
        color: $gray-500 !important;
      }
      a {
        transition: all 0.6s;
        color: $gray-500 !important;
        &:hover {
          transition: all 0.6s;
          color: $primary !important;
        }
      }
    }
    ul {
      li {
        a {
          background: #3a4868 !important;
          border: 1px solid #3a4868;
          color: $gray-500;
          transition: all 0.6s;
          &:hover {
            transition: all 0.6s;
            color: $white !important;
            background: $primary !important;
          }
        }
      }
    }
  }
  .foo-nav {
    position: relative;
    z-index: 1000;
    ul {
      li {
        svg {
          font-size: 6px;
          top: 2px;
        }
        a {
          transition: all 0.5s ease;
          color: $gray-500 !important;
          &:hover {
            color: $primary !important;
          }
        }
      }
    }
  }
  .foo-bottom {
    &.waveWrapper {
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
    .waveWrapperInner {
      position: absolute;
      width: 100%;
      overflow: hidden;
      height: 100%;
      bottom: -1px;
      &.bgTop {
        z-index: 15;
        opacity: 0.5;
      }
      &.bgBottom {
        z-index: 5;
      }
      .wave {
        position: absolute;
        left: 0;
        width: 1000%;
        height: 100%;
        background-repeat: repeat no-repeat;
        background-position: 0 bottom;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        &.waveTop {
          // background-image: url('https://webstrot.com/html/jbdesk/main_version/main_pages/images/wave1.png');
          background-size: 37% 100px;
        }
        &.waveBottom {
          // background-image: url('https://webstrot.com/html/jbdesk/main_version/main_pages/images/wave1.png');
          background-size: 37% 100px;
          -webkit-animation: move_wave 25s linear infinite;
          animation: move_wave 25s linear infinite;
        }
      }
    }
  }
  .copyright {
    position: relative;
    z-index: 1000;
    color: $gray-500 !important;
    a {
      color: $gray-500 !important;
      transition: all 0.6s;
      &:hover {
        transition: all 0.6s;
        color: $primary !important;
      }
    }
  }
  .fav-icon {
    color: #fff !important;
  }
}
