* h2 {
  font-family: "Segoe UI";
}
.choose_japan_outline {
  &_bkg_image {
    background: url("../../images/landing/choose_japan/why-japan.png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -15px !important;
    width: 100vw;
    padding: 85px 0px;
  }
  @media (max-width: "650px") {
    &_bkg_image {
      background: url("../../images/landing/choose_japan/ChooseJapan.png");
      height: 450px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: -15px !important;
      width: 100vw;
      padding: 60px 35px;
    }
  }
  &_scholarship_bkg_image {
    background: url("../../images/landing/choose_japan/scholarship.png");
    // height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -15px !important;
    width: 100vw;
    padding: 85px 0px;
  }
  @media (max-width: "900px") {
    &_scholarship_bkg_image {
      background: url("../../images/landing/choose_japan/scholarship.png");
      // height: 450px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: -15px !important;
      width: 100vw;
      padding: 30px 40px;
    }
  }
  &_cards {
    background-color: #fff;
    border-radius: 20px;
    width: 620px;
  }
  &_card {
    text-align: center;
    h3 {
      font-weight: bold;
    }
    div {
      div {
        padding-bottom: 10px;
        span:last-child {
          text-align: left;
        }
      }
    }
    &_num {
      border-radius: 50%;
      background-color: #10a9ac;
      color: #fff;
      width: 45px;
      height: 45px;
      font-weight: bold;
      font-size: 2rem;
    }
    &_list {
      padding: 10px;
      color: black;
      // font-size: 20px;
    }
  }
}

.csa_outline {
  text-align: center;
  // height: 500px;
  margin: 50px 0px;
  &_heading {
    padding-bottom: 40px;
    h3 {
      font-weight: bold;
    }
  }
  &_mid_sec {
    justify-content: center;
    &_img {
      img {
        box-shadow: 7px 7px 0px 0px hsla(181, 83%, 37%, 0.3);
        border-radius: 50%;
      }
    }
    &_align_text {
      position: relative;
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-30%, -50%);
        color: white;
        font-size: 22px;
      }
    }
    &_scout {
      position: relative;
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 22px;
      }
    }
  }
  &_button_sec {
    padding: 20px 90px;
    background: #fda520;
    color: white;
    border-radius: 40px;
    font-weight: bold;
    font-size: 20px;
  }
}
// Choose Japan STat
.landing_choose_japan_stat {
  &_bkg_image {
    background: url(../../images/landing/choose_japan/choose-japan-stat.png)
      repeat;
    // height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    margin-left: -30px;
    text-align: center;
    position: relative;
  }
  &_bkg_image::before {
    background-color: rgba(16, 169, 172, 0.4);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  &_wrk_bkg_image {
    // background: url("../../images/landing/choose_japan/work-in-japan.png");
    background: url(../../images/landing/choose_japan/work-in-japan.png) repeat;
    // height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    margin-left: -30px;
    text-align: center;
    position: relative;
  }
  &_wrk_bkg_image::before {
    background-color: rgba(16, 169, 172, 0.6);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  // &_wrk_bkg_image:after {
  //   position: absolute;
  //   content: " ";
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 0;
  //   background-color: rgba(16, 169, 172, 0.8)
  // }
  // @media (max-width: 480px) {
  //   &_wrk_bkg_image {
  //     height: 570px;
  //   }
  // }
  &_heading {
    margin-top: 50px;
    h3 {
      font-weight: bold;
      color: white;
    }
  }
  &_bargraph {
    padding: 15px;
    // background-color: white;
    border-radius: 10px;
    margin-top: -15px;
    .row {
      justify-content: center;
      div {
        h5 {
          color: white;
          font-weight: bold;
        }
      }
    }
    @media (min-width: 1400px) {
      &_single {
        padding: 0px 320px;
        div {
          background-color: white;
          text-align: center;
          padding-top: 2px;
          padding-bottom: 2px;
          border-radius: 10px;
        }
      }
    }

    @media (max-width: 1399px) {
      &_single {
        // padding: 0px 500px;
        div {
          background-color: white;
          text-align: center;
          padding-top: 2px;
          padding-bottom: 2px;
          border-radius: 10px;
        }
      }
    }
  }
  &_bargraph1 {
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    margin-top: -15px;
    .row {
      justify-content: center;
      div {
        h5 {
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}

// Carrer component
.landing-career-component {
  &_heading {
    margin-top: 50px;
    text-align: center;
    padding-bottom: 50px;
    h3 {
      font-weight: bold;
    }
  }
  &_images {
    &_align {
      img {
        height: 300px;
        width: 300px;
        box-shadow: 5px 5px 0px 0px hsla(181, 83%, 37%, 0.3);
        border-radius: 50%;
      }
      @media (min-width: 770px) and (max-width: 900px) {
        img {
          height: 250px;
          width: 250px;
        }
      }
      @media (min-width: 630px) and (max-width: 769px) {
        img {
          height: 200px;
          width: 200px;
        }
      }
      @media (min-width: 515px) and (max-width: 629px) {
        img {
          height: 150px;
          width: 150px;
        }
      }
      @media (max-width: 514px) {
        img {
          height: 100px;
          width: 100px;
        }
      }
    }
    div {
      justify-content: center;
    }

    &_circle {
      background-color: #fda520;
      box-shadow: 5px 5px 0px 0px hsla(181, 83%, 37%, 0.3);
      color: white;
      border-radius: 50%;
      width: 300px;
      height: 300px;
      text-align: center;
      &_inside_text {
        transform: translate(0px, 90px);
        padding: 0px 10px;
        div:first-of-type {
          font-size: 20px;
          div {
            font-size: 20px;
            font-weight: bold;
          }
        }
        div:last-of-type {
          font-size: 11px;
          padding: 0px 20px;
        }
      }
    }
    @media (min-width: 770px) and (max-width: 900px) {
      &_circle {
        width: 250px;
        height: 250px;
        &_inside_text {
          transform: translate(0px, 75px);

          div:first-of-type {
            font-size: 14px;
            div {
              font-size: 20px;
              font-weight: bold;
            }
          }
          div:last-of-type {
            font-size: 10px;
            padding: 0px 20px;
          }
        }
      }
    }
    @media (min-width: 630px) and (max-width: 769px) {
      &_circle {
        width: 200px;
        height: 200px;
        &_inside_text {
          transform: translate(0px, 50px);

          div:first-of-type {
            font-size: 12px;
            div {
              font-size: 20px;
              font-weight: bold;
            }
          }
          div:last-of-type {
            font-size: 9px;
            margin-top: -4px;
            padding: 0px 7px;
          }
        }
      }
    }
    @media (min-width: 515px) and (max-width: 629px) {
      &_circle {
        width: 150px;
        height: 150px;
        &_inside_text {
          transform: translate(0px, 32px);

          div:first-of-type {
            font-size: 9px;
            div {
              font-size: 12px;
              font-weight: bold;
            }
          }
          div:last-of-type {
            font-size: 7px;
            padding: 0px;
            margin-top: -5px;
          }
        }
      }
    }
    @media (max-width: 514px) {
      &_circle {
        width: 100px;
        height: 100px;
        &_inside_text {
          transform: translate(0px, 15px);

          div:first-of-type {
            font-size: 6px;
            div {
              font-size: 8px;
              font-weight: bold;
            }
          }
          div:last-of-type {
            font-size: 4px;
            padding: 0px;
          }
        }
      }
    }
  }
}

//Scholarship
.scholarship_outline {
  &_bkg_image {
    background: url("../../images/landing/choose_japan/scholarship.png");
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -15px !important;
    position: relative;
  }
  &_bkg_image::before {
    background-color: rgba(255, 255, 255, 0.4);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  &_card {
    text-align: center;
  }
}
//   CSA Graph Component
.csa_fig_outline {
  text-align: center;
  // height: 700px;
  margin: 50px 0px;
  &_heading {
    padding-bottom: 40px;
    h3 {
      font-weight: bold;
    }
  }
  &_mid_sec {
    justify-content: center;
    img {
      height: 680px;
      width: 1050px;
      object-fit: cover;
    }
    @media (min-width: 770px) and (max-width: 850px) {
      img {
        height: 500px;
        object-fit: cover;
      }
    }
    @media (max-width: 769px) {
      img {
        height: 280px;
        object-fit: cover;
      }
    }
  }
  &_button_sec {
    padding: 20px 90px;
    background: #fda520;
    color: white;
    border-radius: 40px;
    font-weight: bold;
    font-size: 20px;
  }
}
// Our Facts
.our_facts_outline {
  text-align: center;
  &_header {
    h3 {
      font-weight: bold;
    }
  }
  &_image {
    div {
      // column-gap: 65px;
      justify-content: center;
      padding-bottom: 10px;
    }
    &_caption {
      font-size: 13px;
      padding: 0px 100px;
    }
    @media (max-width: 1000px) {
      &_caption {
        padding: 0px;
      }
    }
  }
}
