.latest-jobs {
  .nav-link {
    border: 0 !important;
  }
  @media (max-width: 470px) {
    .tab-content {
      .tab-pane {
        ul {
          .job-for {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
