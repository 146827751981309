.filters {
  // background-image: url(https://webstrot.com/html/jbdesk/main_version/main_pages/images/map_banner.jpg);
  height: 100%;
  background-position: center 0;
  background-size: cover;
  @media (min-width: 992px) and (max-width: 1600.98px) {
    .card-body {
      padding: 70px 20px !important;
    }
  }
  @media (max-width: 575.98px) {
    .card-body {
      padding: 70px 20px !important;
    }
  }
  .trending-keyword {
    svg,
    li,
    a {
      color: $white !important;
    }
  }
  .form-control {
    background: #3e4f4c !important;
    span,
    svg,
    .react-dropdown-select-input {
      color: $white !important;
    }
    .react-dropdown-select-item {
      color: $gray-800 !important;
    }
  }
  .handle {
    height: 15px !important;
    width: 15px !important;
  }
  .track,
  .highlight {
    top: 15px !important;
  }
}
