//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link {
    border: $nav-tabs-border-width solid transparent;

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }
}

//
// Pills
//

.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

.sidebar-nav {
  .active {
    background: #f8f8f8;
    border-left: 3px solid $primary;
  }
  li {
    a {
      &:hover {
        border-left: 3px solid $primary;
        background: #f8f8f8;
      }
    }
  }
}
