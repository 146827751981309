.school-std-search {
  margin: 20px 60px;
  border: 1px solid black;
  border-radius: 20px;
  justify-content: space-between;

  .right {
    width: 15%;
  }

  div {
    span {
      .css-1s2u09g-control {
        width: 210px;
        //border-radius: none !important;
        //border-radius: 0px !important;

        //border:none !important;
        &:hover {
          width: 210px !important;
        }
      }

      .css-6j8wv5-Input {
        width: 210px;
      }
    }
  }

  .css-1okebmr-indicatorSeparator {
    width: 0px !important;
  }

  .left {
    div {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  //   &:after {
  //     content: "";
  //     display: table;
  //     clear: both;
  //   }

  //   div {
  //     float: left;
  //     width: 33.33%;
  //     padding: 10px;
  //     border-right: 1px solid black;

  //     select{
  //         border:none;
  //         width: -webkit-fill-available;
  //     }
  //   }
  //   div:last-child{
  //       border-right: none !important;
  //   }

  &-list {
    margin: 20px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
  }

  &-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;

    &-id {
      text-align: center;
    }

    &-highlight {
      text-align: center;
      background-color: $green-blue;
      border-radius: 20px;
      color: white;
    }

    &-highlight-applied {
      text-align: center;
      background-color: #FDA520;
      border-radius: 20px;
      color: white;
    }

    &-danger {
      text-align: center;
      background-color:  #5476DE;
      border-radius: 20px;
      color: white;
    }
  }

  &-color {
    color: $green-blue;
  }

  &-content {
    justify-content: space-between;
  }

  &-gap {
    div {
      margin-bottom: 16px;
    }
  }

  &-paragraph {
    background-color: #f8f9fa;
    padding: 5px 16px;
  }
}