.sidebar-nav {
  .active {
    background: #f8f8f8;
    border-left: 3px solid $primary;
  }
  li {
    padding: 0 !important;
    a {
      transition: all 0.3s ease;
      border-left: 3px solid transparent;
      display: block;
      padding: 0.75rem 1.25rem;
      &:hover {
        transition: all 0.3s ease;
        border-left: 3px solid $primary;
        background: #f8f8f8;
        border-radius: 0;
      }
    }
  }
  .progress {
    height: 5px;
  }
}
