.best-jobs {
  // background-image: url(https://webstrot.com/html/jbdesk/main_version/main_pages/images/double_shape.png);
  background-position: center 0;
  background-size: cover;
  .best-jobs-tabs {
    .nav-tabs {
      .nav-item {
        transition: all 0.5s;
        .nav-link {
          padding: 15px 40px !important;
          border: 1px solid rgba(0, 0, 0, 0.125);
          border-radius: 0;
          @media (max-width: 575.98px) {
            padding: 15px 20px !important;
          }
          &.active {
            color: $white !important;
            border: 1px solid $primary;
            background: $primary !important;
          }
        }
      }
    }
  }
}

.best-jobs-two {
  .line-shape {
    top: 30%;
    left: -150px;
  }
  .round-shape {
    right: -150px;
    bottom: -5%;
  }
  .bestjob-two-item {
    border-radius: 100px;
    @media (max-width: 767.98px) {
      border-radius: 30px !important;
    }
    @media (max-width: 575.98px) {
      border-radius: 10px !important;
    }
    &.media-heading,
    p {
      transition: all 0.5s;
    }
    .card-body {
      padding: 40px 30px;
    }
    &:hover {
      //   background: $blue-violet-gradient;
      background: $green-blue-gradient;
      .media-heading,
      p {
        color: $white !important;
      }
    }
  }
  .best-jobs-tabs {
    .nav-tabs {
      border: 1px solid rgba(0, 0, 0, 0.125) !important;
      border-radius: 100px;
      .nav-item {
        transition: all 0.5s;
        .first-nav {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
          &.active {
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
          }
        }
        .last-nav {
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          &.active {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
          }
        }
        .nav-link {
          padding: 15px 40px !important;
          @media (max-width: 576px) {
            padding: 15px 20px !important;
          }
          border: 0 !important;
          &.active {
            color: $white !important;
            //   border: 1px solid $blue-violet;
            //   background: $blue-violet-gradient;
            border: 1px solid $green-green;
            background: $green-green-gradient;
          }
        }
      }
    }
  }
  .btn {
    // background: $blue-violet-gradient;
    // border: $blue-violet;
    background: $green-blue-gradient;
    border: $green-blue;
  }
}
