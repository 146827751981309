.setting {
  width: 280px;
  height: auto;
  position: fixed;
  right: -280px;
  top: 30%;
  background: rgba($color: $white, $alpha: 0.75);
  z-index: 1099;
  border-radius: 0 0 0 5px;
  transition: all 0.3s ease;
  padding: 30px;
  .setting-btn {
    position: absolute;
    top: 0;
    left: -50px;
    width: 50px;
    height: 50px;
    background: $white;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
  }
  &.show {
    right: 0;
  }
  .color-plate {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    li {
      width: 60px;
      height: 40px;
      margin: 0 10px;
      border-radius: 0.25rem;
      cursor: pointer;
    }
  }
}

.bg-default {
  background: $default !important;
}

.bg-violet {
  background: $blue-violet-gradient !important;
}

.bg-green {
  background: $green !important;
}
