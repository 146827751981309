.signup-form {
  max-width: 600px!important;
  @media (max-width: 575.98px) {
    max-width: 400px!important;
    // padding: $spacer * 4 30px !important;
  }
  // @media (max-width: 575.98px) {
  //   padding: $spacer * 3 $spacer * 3 !important ;
  // }
}
